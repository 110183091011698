import { ReactNode, SelectHTMLAttributes, forwardRef } from "react";
import { HiExclamationCircle } from "react-icons/hi";

import { classNames } from "@shared/utils";

import Label from "./Label";
import Text from "./Text";

export type SelectProps = {
  id?: string;
  name: string;
  label: string;
  className?: string;
  defaultValue?: string;
  error?: ReactNode;
  hint?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  options: Array<{ label: string; value: string }>;
  value?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      name,
      label,
      className,
      disabled,
      required,
      error,
      hint,
      options,
      placeholder,
      value,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={classNames("space-y-2", className)}>
        <Label htmlFor={id || name} required={required}>
          {label}
        </Label>
        <div className="relative">
          <select
            className={classNames(
              {
                "border-gray-300  focus:ring-primary-500 focus:border-primary-500":
                  error === undefined,
                "opacity-50 cursor-not-allowed": disabled,
                "border-red-300 focus:ring-red-500 focus:border-red-500 pr-10":
                  error,
                "text-red-300": error && !value,
                "text-red-900": error && value,
                "text-gray-400": !error && !value,
                "text-gray-900": !error && value,
              },
              "shadow-sm block w-full rounded-md text-sm",
            )}
            ref={ref}
            id={id || name}
            name={name}
            required={required}
            value={value}
            {...props}
          >
            <option value="" disabled={required}>
              {placeholder}
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {error ? (
            <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
              <HiExclamationCircle
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          ) : null}
        </div>
        {!error && hint ? (
          <Text
            as="p"
            size="sm"
            color="muted"
            className="mt-2"
            id={`${id || name}-description`}
          >
            {hint}
          </Text>
        ) : null}
        {error ? (
          <Text
            as="p"
            size="sm"
            color="danger"
            className="mt-2"
            id={`${id || name}-description`}
          >
            {error}
          </Text>
        ) : null}
      </div>
    );
  },
);

Select.displayName = "Select";

export default Select;
